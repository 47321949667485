import {Card, CardContent, CardMedia, Grid, makeStyles, Typography, Hidden} from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import door300 from '../images/door-300.jpg'
import fraVeien from '../images/schive/DSC07031.jpg'
import skilt from '../images/schive/DSC07062.jpg'

const useStyles = makeStyles({
  root:{
    padding: "3rem",
    justifyContent: 'center',
    textAlign: 'center',
    margin:'auto',
    maxWidth: 1200
},
title:{
  fontSize: 50,
  marginBottom:'3rem'
},
subtitle:{
  fontSize: 25,
  marginBottom:'2rem'
},
card:{
  justifyContent:'center'
},
cardImage:{
  height:400,
  width:600,
  marginBottom:0
},
cardContent:{
  maxWidth:500,
  textAlign:'center',
  margin:'auto'
}
})





const AboutPage = () => {


  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.root}>
        <Typography className={classes.title}>Om Melhus Minilager</Typography>
        <Card className={classes.card}>
          <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
          <Hidden smDown>
          <CardMedia 
            className={classes.cardImage}
            component="img"
            image={door300}
          />
          </Hidden>
          </Grid>
          
          <Grid item xs={12} md={6}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.subtitle}>Slik funker det</Typography>
            <Typography>
              <ul>
                <li>Du legger inn en bestilling her i nettløsningen vår</li>
                <li>Vi kontakter deg på e-post med din tildelte bod og faktura</li>
                <li>Du får tilgang til den automatiske porten via telefon</li>
                <li>Du åpner ytterporten ved behov, og sikrer boden din med egen hengelås</li>
              </ul>
            </Typography>
          </CardContent>
          </Grid>

          <Grid item xs={12} md={6}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.subtitle}>Våre lokaler</Typography>
            <Typography>
              <ul>
                <li>Kaldlager ferdigstilt i 2021</li>
                <li>Boder med stor (3.40m) takhøyde</li>
                <li>Alle boder i første etasje</li>
                <li>Stor port du åpner med mobilen</li>
                <li>Enkel tilgang med kjøretøy</li>
                <li>Tralle for enkel transport av eiendeler inne i lokalene</li>
              </ul>
            </Typography>
          </CardContent>
          </Grid>
          <Grid item xs={12} md={6}>
          <Hidden smDown>
          <CardMedia
            className={classes.cardImage}
            component="img"
            image={fraVeien}
          />
          </Hidden>

          </Grid>
          <Grid item xs={12} md={6}>
          <Hidden smDown>
          <CardMedia
            className={classes.cardImage}
            component="img"
            image={skilt}
          />
          </Hidden>

          </Grid>
          <Grid item xs={12} md={6}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.subtitle}>Åpningstider og Kontakt</Typography>
            <Typography>Som kunde har du adgang til boden din mellom 0600 og 2400</Typography>
            <br/>
            <Typography>
              Om du behøver assistanse eller har spørsmål kan du kontakte oss på 483 00 609, eller post@melhus-minilager.no

            </Typography>
          </CardContent>
          </Grid>
          </Grid>
        </Card>


      </div>
      
    </Layout>
  )
}
  export default AboutPage
  